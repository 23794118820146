<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <BRow>
        <BCol>
          <BCard header-tag="header" v-if="user">
            <div slot="header">
              <strong><BIconPersonCheckFill/> 사용자 정보 설정</strong>
              <div class="card-header-actions">
                <small class="text-muted"><strong class="alert-danger">{{user.userId}}</strong>의 사용자 정보를 설정 합니다</small>
              </div>
            </div>
            <BForm @submit.prevent="openPasswordModal">
              <BRow>
                <BCol>
                  <BFormGroup class="mb-1" id="form-01" label="ID" label-cols="2" label-for="input-id" label-align="right">
                    <BFormInput id="input-id"
                                v-model="user.userId"
                                type="text"
                                readonly/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-010" label="이름" label-cols="2" label-for="display-name" label-align="right">
                    <BFormInput id="display-name"
                                v-model="user.name"
                                type="text"
                                placeholder="이름을 입력 하세요"
                                required/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-06" label="표시명" label-cols="2" label-for="input-user-nick" label-align="right">
                    <BFormInput id="input-user-nick"
                                v-model="user.nick"
                                type="text"
                                placeholder="표시명 명 입력"/>
                  </BFormGroup>

                  <BFormGroup class="mb-1" id="form-0721" label="직책" label-cols="2" label-for="input-position" label-align="right">
                    <BFormInput id="input-position"
                                type="text"
                                v-model="user.position"
                                placeholder="직책 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-0722" label="직급" label-cols="2" label-for="input-grade" label-align="right">
                    <BFormInput id="input-grade"
                                type="text"
                                v-model="user.grade"
                                placeholder="직급 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-07" label="휴대전화" label-cols="2" label-for="input-phone-no" label-align="right">
                    <BFormInput id="input-phone-no"
                                type="text"
                                v-model="user.hpNo"
                                placeholder="전화 번호 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-071" label="전화번호" label-cols="2" label-for="input-phone-int" label-align="right">
                    <BFormInput id="input-phone-int"
                                type="text"
                                v-model="user.phoneNo"
                                placeholder="내선 전화 번호 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-08" label="이메일" label-cols="2" label-for="input-email" label-align="right">
                    <BFormInput id="input-email"
                                v-model="user.email"
                                type="text"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-0601" label="부서" label-cols="2" label-for="input-position" label-align="right">
                    <BFormInput id="input-dept"
                                type="text"
                                v-model="user.department"
                                placeholder="부서 입력"/>
                  </BFormGroup>
<!--                  <BFormGroup class="mb-1" id="form-09" label="접속센터" label-cols="2" label-for="input-center" label-align="right">-->
<!--                    <BFormSelect id="input-center"-->
<!--                                 style="background-color:#2f303a"-->
<!--                                 v-model="user.center"-->
<!--                                 :options="centerOpts" readonly/>-->
<!--                  </BFormGroup>-->
                  <BFormGroup class="mb-1" id="form-10" label="관제지역" label-cols="2" label-for="input-group" label-align="right">
                    <BFormSelect v-model="user.areas"
                                 :options="areaOpt"
                                 multiple
                                 :select-size="6"
                                 size="sm"
                                 style="background-color:#2f303a"
                                 readonly/>

<!--                    <BFormSelect id="input-group" -->
<!--                                 style="background-color:#2f303a"-->
<!--                                 v-model="user.arCodes"-->
<!--                                 :options="wgOpts" readonly/>-->
<!--                    -->

                  </BFormGroup>
                  <BRow>
                    <BCol class="text-right">
                      <b-button type="submit" variant="primary"
                                :disabled="user.userId===''">저 장</b-button>
                    </BCol>
                  </BRow>
                </BCol>
              </BRow>
            </BForm>
          </BCard>
        </BCol>
        <BCol>
          <BCard class="text-left" footer-tag="footer" footer-bg-variant="light" v-if="user">
            <div slot="header">
              <strong><BIconLockFill/> 비밀번호 설정</strong>
              <div class="card-header-actions">
                <small class="text-muted"><strong class="alert-danger">{{user.userId}}</strong>의 비밀번호를 설정 합니다.</small>
              </div>
            </div>
            <BRow >
              <BCol>
                <BFormInput class="mt-3" v-model="currPwd"  type="password" placeholder="현재 비밀번호를 입력하세요"/>
                <BFormInput class="mt-3" v-model="newPwd"  :state="validPwdState"  max="20" type="password" placeholder="비밀번호는 영문.숫자.특수문자를 포함해야 하며 최소8자 이상입니다" />
                <BFormInput class="mt-2" v-model="rePwd" :state="validPwdConfirm" max="20" type="password" placeholder="비밀번호를 다시 입력해주새요"/>
              </BCol>
            </BRow>
            <BRow class="mt-3" align-h="between">
              <BCol></BCol>
              <BCol class="text-right">
                <BButton  variant="danger" @click="setUserPwd" :disabled="!isPwdChecked">비밀번호 변경</BButton>
              </BCol>
            </BRow>
          </BCard>
        </BCol>
      </BRow>
    </div>

    <!-- Password Confirmation Modal -->
    <b-modal
      ref="passwordModal"
      hide-footer
      centered
      header-bg-variant="dark"
      header-text-variant="warning"
      title="비밀번호 확인">
      <div>
        <b-form @submit.prevent="userSubmit">
          <b-form-group label="비밀번호" label-for="input-current-pwd" label-cols="4" label-align="right">
            <b-form-input id="input-current-pwd"
                          v-model="currentPwd"
                          type="password"
                          placeholder="현재 비밀번호 입력"
                          required/>
          </b-form-group>
          <div class="text-right">
            <b-button type="submit" variant="primary" class="mr-2">저 장</b-button>
            <b-button variant="secondary" @click="hidePasswordModal">취소</b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import '@/common/HelperMixin';
import {
  apiCall,
  // cloneVar
} from '@/common/utils';
// import qs from 'querystring';
// import moment from "moment";

/*
const _user = {
  userId: '',
  name: '',
  nickName: '',
  role: '',
  user: '',
  center: '',
  avatar: '',
  position: '',
  email: '',
  phoneNo: '',
  intPhoneNo: '',
  extPhoneNo: '',
  status: '',
  workgroup: '',
  wgCode: '',
  workStatus: '',
  taskAllows: '',
  isDeleted: '',
  isApproved: '',
  approvedAt: '',
  approveId: '',
  remark: '',
};
*/
export default {
  name: 'UserInfo',
  data() {
    return {
      user: null,
      currentPwd: '',
      currPwd: '',
      newPwd: '',
      rePwd: '',
      centerOpts: [{ value: 'CENTER1', text: '기본센터' }],
      wgOpts: [{ value: 'DEFAULT', text: '기본워크그룹' }],
      roleOpts: [
        { value: 'SA', text: '관리자' },
        { value: 'SM', text: '담당자' },
        { value: 'SS', text: '사용자' },
      ],
      actionMode: 'put',
      isPwdChecked: false,
      pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
      areaMap: this.$store.state.area.map,
      areaOpt: [],
    };
  },
  computed: {
    validPwdState() {
      let ret = false;
      if (this.newPwd.length > 7) {
        if (this.pwdRegexp.test(this.newPwd)) ret = true;
      }
      return ret;
    },
    validPwdConfirm() {
      let ret = false;
      const p1 = this.newPwd;
      const p2 = this.rePwd;
      if (p2.length > 7 && p1 === p2) {
        ret = true;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isPwdChecked = true;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isPwdChecked = false;
      }
      return ret;
    },
  },
  async created() {
    try {
      await this.getUserInfo();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    openPasswordModal() {
      this.$refs.passwordModal.show();
    },
    hidePasswordModal() {
      this.$refs.passwordModal.hide();
    },

    setAreaList(){
      if(!this.user) return;


      this.areaOpt = [];
      // this.userAreaOpt = [];
      Object.keys(this.areaMap).map(k=>{
        if(k){
          const disabled = this.user.areas.indexOf(k) > -1
          this.areaOpt.push({value: k, text: this.areaMap[k], disabled: !disabled})
        }
      })

      // this.user.areas.map(e=>{ this.userAreaOpt.push({value:e, text: this.areaMap[e]})});
    },


    async userSubmit() {
      let r = null;
      try {
        const param = {
          ...this.user,
          currentPwd: btoa(this.currentPwd),
        };
        r = await apiCall("PUT", `/api/user/my-info/${this.user._id}`, param);
        if (r.code === 200) {
          await this.alertModal('사용자 정보가 저장 되었습니다.');
          this.hidePasswordModal();
          this.currentPwd = ''; // Clear password field
        } else {
          await this.alertWarn(`[${r.code}] ${r.message}`);
          this.currentPwd = ''; // Clear password field
        }
      } catch (err) {
        console.log(err);
        await this.alertError(err);
        this.currentPwd = ''; // Clear password field
      }
    },
    async getUserInfo() {
      console.log("======================getUserInfo========================");
      let r = null;
      try {
        r = await apiCall('get', `/api/user/my-info`);
        console.log("/api/user/my-info ---- result--------->", r);
        if (r.code === 200) {
          this.user = r.result;
          this.setAreaList();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async setUserPwd() {
      console.log("setUserPwd ---> ");
      try {
        const msg = `${this.user.userId}의 비밀번호를 변경 합니다.` +
          ` 변경 후 로그아웃 됩니다. 진행 하시겠습니까?`;
        if (!(await this.confirmModal(msg, '비밀번호 변경')))
          return;

        const param = {
          currPwd: btoa(this.currPwd),
          newPwd: btoa(this.newPwd),
          rePwd: btoa(this.rePwd)
        };
        const r = await apiCall('post', `/api/auth/pwd-change`, param);
        if (r.result) {
          await this.alertModal("비밀번호가 변경 되었습니다", "비밀번호 변경");
          await this.$store.dispatch('LOGOUT', 'P100');
        } else {
          await this.alertWarn('비밀번호 변경 실패: ' + r.message, 'Error:' + r.code);
        }
      } catch (err) {
        console.log(err);
        await this.alertError(err);
      }
    },
  }
}
</script>

<style scoped>
.dark-modal .modal-content {
  background-color: #2f303a;
  color: white;
}

.dark-modal .modal-header {
  border-bottom: 1px solid #444;
}

.dark-modal .modal-footer {
  border-top: 1px solid #444;
}
</style>
